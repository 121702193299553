import { useEffect } from 'react';

export default function useDocumentTitle(documentTitle: string) {
  useEffect(() => {
    const previousTitle = document.title;

    document.title = documentTitle;

    return () => {
      document.title = previousTitle;
    };
  }, [documentTitle]);
}
