import { Component, PropsWithChildren } from 'react';
import axios from 'axios';
import { AssetBasedTranslationContextProvider, SupportedUserLanguage } from '../../contexts/TranslationContext';
import Error from './Error';
import Maintenance from './Maintenance';
import { RefreshTokenFailedError } from '../../api/BearerToken';
import AutoSignedOff from './AutoSignedOff';

type Props = PropsWithChildren<{}>;

type State = { error: unknown | null };

class ErrorBoundary extends Component<Props, State> {
  lang: SupportedUserLanguage = process.env.REACT_APP_P_STUDIO_LANGUAGE;
  state: State = { error: null };

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  determineErrorComponent() {
    if (axios.isAxiosError(this.state.error) && this.state.error.response?.status === 503) return <Maintenance />;
    if (this.state.error instanceof RefreshTokenFailedError) return <AutoSignedOff />;
    return <Error />;
  }

  render() {
    if (this.state.error) {
      return (
        <AssetBasedTranslationContextProvider lang={this.lang}>
          {this.determineErrorComponent()}
        </AssetBasedTranslationContextProvider>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
