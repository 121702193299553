import axios from 'axios';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { API_BASE_URL } from '../api';

import TranslationContext, { AssetBasedTranslationContextProvider, SupportedUserLanguage } from './TranslationContext';
import flatObject from '../utils/flatObject';
import useThrowAsyncError from '../hooks/useThrowAsyncError';

type Overrides = Record<string, unknown>;

function getTranslations(defaultTranslations: Record<string, string>, overrides?: Overrides) {
  if (!overrides) return defaultTranslations;

  const flattenedOverrides = Object.fromEntries(flatObject(overrides));

  return { ...defaultTranslations, ...flattenedOverrides };
}

function TranslationContextProvider({ children }: { children: ReactNode }): JSX.Element {
  const {
    lang,
    translations: { nl, fr },
  } = useContext(TranslationContext);

  const [nlOverrides, setNlOverrides] = useState<Overrides>();
  const [frOverrides, setFrOverrides] = useState<Overrides>();

  const handleError = useThrowAsyncError();

  useEffect(() => {
    async function fetchTranslationOverrides(): Promise<void> {
      // Standard api is not used here because the maintenance page uses this context.
      const res = await axios.get(`${API_BASE_URL}/studio/languages/${lang}`, {
        headers: { StudioGroup: process.env.REACT_APP_P_STUDIO_GROUP },
      });

      const location = res.status === 204 ? undefined : res.data.location;

      if (location) {
        const { data } = await axios.get(location);

        if (lang === 'fr') setFrOverrides(data);
        else setNlOverrides(data);
      }
    }

    fetchTranslationOverrides().catch(handleError);
  }, [lang, handleError]);

  const nlWithOverrides = useMemo(() => getTranslations(nl, nlOverrides), [nl, nlOverrides]);
  const frWithOverrides = useMemo(() => getTranslations(fr, frOverrides), [fr, frOverrides]);
  const translationContextValue = useMemo(
    () => ({ lang, translations: { nl: nlWithOverrides, fr: frWithOverrides } }),
    [lang, nlWithOverrides, frWithOverrides],
  );

  return <TranslationContext.Provider value={translationContextValue}>{children}</TranslationContext.Provider>;
}

export default function TranslationContextProviderWrapper({
  lang,
  children,
}: {
  lang: SupportedUserLanguage;
  children: ReactNode;
}): JSX.Element {
  return (
    <AssetBasedTranslationContextProvider lang={lang}>
      <TranslationContextProvider>{children}</TranslationContextProvider>
    </AssetBasedTranslationContextProvider>
  );
}
