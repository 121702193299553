import Role from './role';
import roleByWeight from './role-by-weight';

class HighestRoleExtractor {
  static extract(roles: Role[]) {
    const distinctRoles = new Set([Role.STUDENT, ...roles]);

    return [...distinctRoles].sort(roleByWeight).slice(-1)[0];
  }
}

export default HighestRoleExtractor;
