import classNames from 'classnames';
import Thumbnail from '../../components/Thumbnail';

type PelckmansHeaderProps = {
  title: string;
  subtitle?: string;
  logo?: boolean;
};

const PelckmansHeader = ({ title, subtitle, logo }: PelckmansHeaderProps): JSX.Element => {
  return (
    <header className="header header--gradient header--pelckmans" role="banner">
      <div className="base-container">
        <div className="header__wrapper">
          {logo && (
            <Thumbnail
              img={process.env.PUBLIC_URL + '/images/p_logo.svg'}
              title="Logo"
              className={classNames(
                'thumbnail--outline',
                'header__thumbnail',
                'header__thumbnail--left',
                'header__thumbnail--logo',
              )}
            />
          )}
          <div className="header__content">
            <h1 className="header__title">{title}</h1>
            {subtitle && <div className="header__subtitle">{subtitle}</div>}
          </div>
        </div>
      </div>
    </header>
  );
};

export default PelckmansHeader;
