import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import useThrowAsyncError from '../../../hooks/useThrowAsyncError';
import { App, getAppsForModule } from '../_api/AppsApi';
import ModuleContext from './ModuleContext';

type AppsContextValue = {
  apps: App[];
};

const AppsContext = createContext<AppsContextValue>({
  apps: [],
});

export default AppsContext;

export function AppsContextProvider({ children }: PropsWithChildren) {
  const handleError = useThrowAsyncError();

  const { module } = useContext(ModuleContext);
  const [apps, setApps] = useState<App[]>([]);

  useEffect(() => {
    const fetchApps = async () => {
      if (!module) return;

      try {
        const { data } = await getAppsForModule(module.id);

        setApps(data);
      } catch (e) {
        handleError(e);
      }
    };

    fetchApps();
  }, [handleError, module]);

  return <AppsContext.Provider value={{ apps }}>{children}</AppsContext.Provider>;
}
