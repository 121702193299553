import { PropsWithChildren, ReactNode } from 'react';
import { ModalContextProvider } from '../contexts/ModalContext';
import ModuleHeader from '../modules/material/_components/common/ModuleHeader';

export default function MaterialPage({ children, sidebar }: PropsWithChildren<{ sidebar: ReactNode }>) {
  return (
    <ModalContextProvider>
      <ModuleHeader />
      {sidebar}
      <main>{children}</main>
    </ModalContextProvider>
  );
}
