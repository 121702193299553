import HighestRoleExtractor from './highest-role-extractor';
import Role from './role';

class Identity {
  userId: string | undefined;
  firstname: string;
  lastname: string | undefined;
  email: string | undefined;
  roles: Role[];
  author_modules: string[];
  data: Record<string, unknown>;

  constructor(
    userId: string | undefined,
    firstname: string,
    lastname?: string,
    email?: string,
    roles?: Role[],
    authorModules?: string[],
    data?: {},
  ) {
    this.userId = userId;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.roles = roles ?? [];
    this.author_modules = authorModules ?? [];
    this.data = data ?? {};

    Object.freeze(this);
  }

  get fullName(): string {
    const names = [];

    if (this.firstname) names.push(this.firstname);
    if (this.lastname) names.push(this.lastname);

    return names.join(' ');
  }

  get isAuthenticated(): boolean {
    return this.userId !== undefined;
  }

  get highestRole(): Role {
    return HighestRoleExtractor.extract(this.roles);
  }

  get isAuthor(): boolean {
    return this.author_modules && this.author_modules.length > 0;
  }

  get isTeacherAlike(): boolean {
    return this.hasAnyRole([
      Role.EDITOR,
      Role.PRODUCTION,
      Role.PUBLISHER,
      Role.SALES,
      Role.SUPPORT,
      Role.SYSADMIN,
      Role.TEACHER,
    ]);
  }

  get canAccessShell(): boolean {
    return this.hasAnyRole([Role.SYSADMIN, Role.EDITOR, Role.PRODUCTION, Role.EXTERNAL]);
  }

  static anonymous(): Identity {
    return new Identity(undefined, 'Anonymous');
  }

  hasAnyRole(roles: Role[] = []): boolean {
    if (roles.length === 0) return this.roles.length > 0;

    return this.roles.some(r => roles.indexOf(r) > -1);
  }

  hasOnlyRole(role: Role): boolean {
    return this.roles.length === 1 && this.roles.indexOf(role) > -1;
  }

  isAuthorForModule(moduleId: string): boolean {
    return this.author_modules.indexOf(moduleId) > -1;
  }
}

export default Identity;
