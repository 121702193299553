import { ShareInfo } from '@pelckmans/business-components/types/Audiences';
import isEqual from 'lodash/isEqual';

type Share = {
  id: string;
};

type Assignment = {
  id?: string;
  from: string;
  to: string;
  repeatable: boolean;
};

export function getSharesAndAssignmentsFromShareInfo(shareInfo?: ShareInfo) {
  const { audiences: newAudiences = [] } = shareInfo || {};

  const shares: Share[] = shareInfo ? newAudiences.filter(x => !x.assignment).map(() => ({ id: shareInfo.id })) : [];

  const assignments = newAudiences
    .filter(x => x.assignment)
    .reduce<Assignment[]>((acc, audienceInfo) => {
      const { assignment } = audienceInfo;

      if (assignment) {
        acc.push({
          id: assignment.id,
          from: assignment.from!.toString(), // type from AssignmentInfo is optional, but why?
          to: assignment.to!.toString(), // type from AssignmentInfo is optional, but why?
          repeatable: assignment.repeatable,
        });
      }

      return acc;
    }, []);

  return { shares, assignments };
}

export type Audience = {
  audienceType: AudienceType;
  schoolId: string;
  groupId?: string;
  userId?: string;
  assignment?: {
    id?: string;
    from?: string;
    to?: string;
    repeatable: boolean;
    timezone: string;
  };
};

export type AudienceType = 'member' | 'group' | 'school' | 'smartschoolschool';

export const getAudiencesByShareState = (
  state: 'shared' | 'unshared',
  audiences: Audience[],
  otherAudiences: Audience[],
): Audience[] => {
  const shares: Audience[] = [];

  audiences.forEach(audience => {
    let otherAudience: Audience | undefined;

    switch (audience.audienceType) {
      case 'school':
        otherAudience = otherAudiences.find(p => p.audienceType === 'school' && p.schoolId === audience.schoolId);
        break;
      case 'smartschoolschool':
        otherAudience = otherAudiences.find(
          p => p.audienceType === 'smartschoolschool' && p.schoolId === audience.schoolId,
        );
        break;
      case 'group':
        otherAudience = otherAudiences.find(p => p.audienceType === 'group' && p.groupId === audience.groupId);
        break;
      case 'member':
        otherAudience = otherAudiences.find(
          p => p.audienceType === 'member' && p.groupId === audience.groupId && p.userId === audience.userId,
        );
        break;
      default:
        break;
    }

    if (!otherAudience) {
      shares.push(audience);
    } else {
      const { assignment } = audience;
      const { assignment: otherAssignment } = otherAudience;

      if (state === 'shared') {
        // update shared free exercise to assignment or update assignment data
        if (assignment && !isEqual(assignment, otherAssignment)) {
          shares.push(audience);
        }
      }

      if (state === 'unshared') {
        // unshare task (becomes shared free exercise)
        if (!otherAssignment && assignment) {
          shares.push(otherAudience);
        }
      }
    }
  });

  return shares;
};
