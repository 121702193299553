import api, { ResponsePayloadWithData } from '../../../api';
import { ContentType } from '../../../modules/material/_api/MedialinksApi';

type ScoreBase = {
  maximum: number;
  achievable: number;
  achieved: number;
  amountOfItems: number;
};

export type Assignment = {
  id: string;
  name: string;
  from: string;
  to: string;
  moduleId: string;
  superModuleId: string;
  subjectName: string;
  materialId: string;
  materialType: 'user-material' | 'medialink';
  contentType?: ContentType;
  score: ScoreBase & {
    achievedAt?: string;
  };
  sharer: {
    firstName: string;
    lastName: string;
  };
};

export async function getMyAssignments() {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<Assignment[]>>(
    `/studio/user/assignments?studioGroup=${process.env.REACT_APP_P_STUDIO_GROUP}`,
  );

  return data;
}
