import classNames from 'classnames';

type BaseProps = {
  active?: boolean;
  className?: string;
};

export type ImageProps = BaseProps & { img?: string; title: string };
export type IconProps = BaseProps & { icon: string };

type Props = ImageProps | IconProps;

const Thumbnail = ({ className, active = false, ...rest }: Props) => {
  return (
    <div className={classNames('thumbnail', className, { 'thumbnail--inactive': !active })} data-testid="thumbnail">
      {'icon' in rest ? (
        <svg className={`thumbnail__icon ${rest.icon}`} role="img">
          <use href={`#${rest.icon}`} data-testid="thumbnail__icon" />
        </svg>
      ) : (
        <img src={rest.img} alt={rest.title} className="thumbnail__image" />
      )}
    </div>
  );
};

export default Thumbnail;
