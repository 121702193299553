import { ContextType, useCallback, useContext } from 'react';
import { render as interpolate } from 'micromustache';

import TranslationContext from '../contexts/TranslationContext';

function getTranslation(
  key: string,
  studioGroup: StudioGroup,
  translations: Record<string, string>,
): string | undefined {
  const keyContainingOverwriteForStudioGroup = `${key}--${studioGroup}`;

  const value = translations[keyContainingOverwriteForStudioGroup] || translations[key];

  if (value && value.startsWith('@:')) {
    const innerTranslationKey = value.substring(2);

    return getTranslation(innerTranslationKey, studioGroup, translations);
  }

  return value;
}

function useTranslation(): {
  t: (key: string, interpolateParams?: Record<string, unknown>) => string;
  lang: ContextType<typeof TranslationContext>['lang'];
} {
  const { lang, translations } = useContext(TranslationContext);

  const translationsForLanguage = translations[lang];

  const { REACT_APP_P_STUDIO_GROUP } = process.env;

  const t = useCallback(
    (key: string, interpolateParams = {}) => {
      const translated = getTranslation(key, REACT_APP_P_STUDIO_GROUP, translationsForLanguage);

      return translated ? interpolate(translated, interpolateParams) : key;
    },
    [translationsForLanguage, REACT_APP_P_STUDIO_GROUP],
  );

  return { t, lang };
}

export default useTranslation;
