import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import useTranslation from '../../hooks/useTranslation';
import useRouting from '../../hooks/useRouting';
import Role from '../../core/security/role';
import MyAssignmentsContext from '../../contexts/MyAssignmentsContext';
import FeatureTogglesContext from '../../contexts/FeatureTogglesContext';
import IdentityContext from '../../contexts/IdentityContext';
import InvitationContext from '../../contexts/InvitationContext';
import LicenseTransferContext from '../../contexts/LicenseTransferContext';
import useLogout from '../../hooks/useLogout';

const AnonymousSidebar = () => {
  const { PUBLIC_URL } = process.env;

  const { t } = useTranslation();
  const { r } = useRouting();

  const { REACT_APP_P_STUDIO_GROUP } = process.env;

  return (
    <ul>
      <li>
        <img src={`${PUBLIC_URL}/images/p_logo.svg`} alt={t('APPLICATION_TITLE')} className="sidebar__logo" />
      </li>
      <li>
        <NavLink to={r(REACT_APP_P_STUDIO_GROUP === 'VO' ? 'login-vo-teacher' : 'login')}>
          <svg className="sidebar__icon" role="img">
            <use xlinkHref="#icon-login" />
          </svg>
          <p className="sidebar__text">{t('NAV_BAR.LOG_ON')}</p>
        </NavLink>
      </li>
      <li>
        <NavLink to={r('helpdesk')}>
          <svg className="sidebar__icon" role="img">
            <use xlinkHref="#icon-info" />
          </svg>
          <p className="sidebar__text">{t('NAV_BAR.HELP')}</p>
        </NavLink>
      </li>
    </ul>
  );
};

const AuthenticatedSidebar = () => {
  const { t } = useTranslation();
  const { r } = useRouting();

  const featureToggles = useContext(FeatureTogglesContext);
  const { identity } = useContext(IdentityContext);
  const logOut = useLogout();
  const { pendingInvitations } = useContext(InvitationContext);
  const { pendingLicenseTransfers } = useContext(LicenseTransferContext);
  const { assignments } = useContext(MyAssignmentsContext);
  const { REACT_APP_ALTERNATE_STUDENT_PLATFORM_URL, REACT_APP_P_STUDIO_GROUP, REACT_APP_SHELL_URL } = process.env;

  const isVoStudent = REACT_APP_P_STUDIO_GROUP === 'VO' && identity.hasOnlyRole(Role.STUDENT);

  return (
    <>
      <ul>
        <li data-testid="dashboard">
          <NavLink to={r('dashboard')}>
            <img
              src={process.env.PUBLIC_URL + '/images/p_logo.svg'}
              alt={t('APPLICATION_TITLE')}
              className="sidebar__logo"
            />
            <p className="sidebar__text" dangerouslySetInnerHTML={{ __html: t('NAV_BAR.HOME') }}></p>
          </NavLink>
        </li>
        {!isVoStudent && (
          <li data-testid="my-account">
            <NavLink to={r('my-account')}>
              {pendingLicenseTransfers.length > 0 && (
                <span className="pill pill--info">{pendingLicenseTransfers.length}</span>
              )}
              <svg className="sidebar__icon" role="img">
                <use xlinkHref="#icon-account" />
              </svg>
              <p className="sidebar__text" dangerouslySetInnerHTML={{ __html: t('NAV_BAR.ACCOUNT') }} />
            </NavLink>
          </li>
        )}
        {identity.isTeacherAlike && featureToggles.GROUP_MGMT && (
          <li>
            <NavLink to={r('my-groups')}>
              <svg className="sidebar__icon" role="img">
                <use xlinkHref="#icon-people" />
              </svg>
              <p className="sidebar__text" dangerouslySetInnerHTML={{ __html: t('NAV_BAR.GROUPS.MY_CLASSES') }} />
            </NavLink>
          </li>
        )}

        {!isVoStudent && (
          <>
            {identity.hasOnlyRole(Role.STUDENT) && featureToggles.GROUP_MGMT && (
              <>
                <li data-testid="my-teachers">
                  <NavLink to={r('my-teachers')}>
                    {pendingInvitations.length > 0 && (
                      <span className="pill pill--info">{pendingInvitations.length}</span>
                    )}
                    <svg className="sidebar__icon" role="img">
                      <use xlinkHref="#icon-people" />
                    </svg>
                    <p
                      className="sidebar__text"
                      dangerouslySetInnerHTML={{ __html: t('NAV_BAR.GROUPS.MY_TEACHERS') }}
                    />
                  </NavLink>
                </li>
                <li data-testid="my-assignments">
                  <NavLink to={r('my-assignments')}>
                    {assignments.length > 0 && <span className="pill pill--assignments">{assignments.length}</span>}
                    <svg className="sidebar__icon" role="img">
                      <use xlinkHref="#icon-assignment" />
                    </svg>
                    <p className="sidebar__text" dangerouslySetInnerHTML={{ __html: t('NAV_BAR.ASSIGNMENTS') }} />
                  </NavLink>
                </li>
              </>
            )}

            <li data-testid="helpdesk">
              <NavLink to={r('helpdesk')}>
                <svg className="sidebar__icon" role="img">
                  <use xlinkHref="#icon-info" />
                </svg>
                <p className="sidebar__text">{t('NAV_BAR.HELP')}</p>
              </NavLink>
            </li>
            <li data-testid="catalogue">
              <NavLink to={r('catalog')}>
                <svg className="sidebar__icon" role="img">
                  <use xlinkHref="#icon-offer" />
                </svg>
                <p className="sidebar__text" dangerouslySetInnerHTML={{ __html: t('NAV_BAR.CATALOG') }} />
              </NavLink>
            </li>
          </>
        )}

        {featureToggles.SHOW_ALTERNATE_STUDENT_PLATFORM_LINK && (
          <li>
            <a href={REACT_APP_ALTERNATE_STUDENT_PLATFORM_URL}>
              <svg className="sidebar__icon" role="img">
                <use xlinkHref="#icon-star-cube" />
              </svg>
              <p
                className="sidebar__text"
                dangerouslySetInnerHTML={{ __html: t('NAV_BAR.ALTERNATE_STUDENT_PLATFORM') }}
              />
            </a>
          </li>
        )}

        <li data-testid="logout">
          <button onClick={logOut} type="button">
            <svg className="sidebar__icon" role="img">
              <use xlinkHref="#icon-logout" />
            </svg>
            <span className="sidebar__text">{t('NAV_BAR.LOG_OFF')}</span>
          </button>
        </li>
      </ul>
      {identity.canAccessShell && (
        <ul>
          <li>
            <a href={REACT_APP_SHELL_URL}>
              <svg className="sidebar__icon sidebar__icon--greyscale" role="img">
                <use xlinkHref="#icon-settings" />
              </svg>
              <p className="sidebar__text" dangerouslySetInnerHTML={{ __html: t('NAV_BAR.CONTENT_MANAGEMENT') }}></p>
            </a>
          </li>
        </ul>
      )}
    </>
  );
};

const Sidebar = () => {
  const {
    identity: { isAuthenticated },
  } = useContext(IdentityContext);

  return (
    <aside className="aside sidebar__wrapper">
      <nav className="sidebar">{isAuthenticated ? <AuthenticatedSidebar /> : <AnonymousSidebar />}</nav>
    </aside>
  );
};

export default Sidebar;
