import api, { ResponsePayloadWithData } from '../../../api';

export type ExerciseCategory = {
  id: string;
  name: string;
  usage: number;
  score?: {
    maximum: number;
    achievable: number;
    achieved: number;
  };
  categories?: ExerciseCategory[];
};

export const getExerciseCategories = async ({
  moduleId,
}: {
  moduleId: string;
  includeScore?: boolean;
  includeSubModules?: boolean;
}) => {
  const { data } = await api.get<ResponsePayloadWithData<ExerciseCategory[]>>(
    `/studio/modules/${moduleId}/exercise-categories/usage`,
    {
      params: {
        includeScore: true,
        includeSubModules: true,
      },
    },
  );

  return data;
};
