import cookie, { CookieSerializeOptions } from 'cookie';

type SameSiteCookieOptions = {
  sameSite: 'none' | 'lax';
  secure: boolean;
  domain: string;
};

function getSameSiteCookieOptions(): SameSiteCookieOptions | undefined {
  const isSafari12 =
    /(iPhone; CPU iPhone OS 1[0-2]|iPad; CPU OS 1[0-2]|iPod touch; CPU iPhone OS 1[0-2]|Macintosh; Intel Mac OS X.*Version\/1[0-2].*Safari|Macintosh;.*Mac OS X 10_14.* AppleWebKit.*Version\/1[0-3].*Safari)/.test(
      window.navigator.userAgent,
    );
  /**
   * Safari 12 is a supported browser for us which is in the list of
   * incompatible clients for the Same-Site policies.
   * https://www.chromium.org/updates/same-site/incompatible-clients
   */
  if (isSafari12) return undefined;

  const { hostname } = window.location;
  const hostnameParts = hostname.split('.');
  const domain = hostnameParts.slice(-2).join('.');

  const isSecureConnection = window.location.protocol === 'https';
  return {
    sameSite: isSecureConnection ? 'none' : 'lax',
    secure: isSecureConnection,
    domain,
  };
}

const Cookies = {
  get: (name: string) => cookie.parse(document.cookie)[name],
  set: (name: string, value: string, options?: CookieSerializeOptions) => {
    const sameSiteCookieOptions = getSameSiteCookieOptions();
    document.cookie = cookie.serialize(name, value, { ...options, ...sameSiteCookieOptions });
  },
  remove: (name: string) => {
    document.cookie = cookie.serialize(name, '', {
      path: '/',
      expires: new Date('1 Jan 1970 00:00:00 GMT'),
    });
  },
} as const;

export default Cookies;
