import { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';

export type GA = {
  capture: (event: GenericGAEvent) => void;
  set: (field: Record<string, string>) => void;
};

const GA_KEY = 'ga_key';

export type GenericGAEvent<Action = string> = {
  category: string;
  action: Action;
  label: string;
  moduleName?: string;
  medialinkType?: string;
  appType?: string;
  exerciseResultViewMode?: string;
  licenseType?: string;
  licenseCode?: string;
  location?: string;
  reference?: string;
  options?: string;
  sort?: string;
};

function mapToGAEvent(event: GenericGAEvent): GAEvent {
  return {
    category: event.category,
    action: event.action,
    label: event.label,
    dimension1: event.moduleName,
    dimension2: event.medialinkType,
    dimension3: event.appType,
    dimension4: event.exerciseResultViewMode,
    dimension5: event.licenseType,
    dimension6: event.licenseCode,
    // Dimension 7 (Role) is set by useGoogleAnalyticsRole
    dimension8: event.location,
    dimension9: event.reference,
    dimension10: event.options,
    dimension11: event.sort,
  };
}

const GoogleAnalyticsContext = createContext<GA | undefined>(undefined);

export default GoogleAnalyticsContext;

const GoogleAnalyticsContextProvider = ({ children }: PropsWithChildren) => {
  const { REACT_APP_ANALYTICS_KEY } = process.env;

  const gaKeyFromSessionStorage = sessionStorage.getItem(GA_KEY);

  const gaKey = gaKeyFromSessionStorage || REACT_APP_ANALYTICS_KEY;

  useEffect(() => {
    if (!gaKeyFromSessionStorage) sessionStorage.setItem(GA_KEY, REACT_APP_ANALYTICS_KEY);
  }, [gaKeyFromSessionStorage, REACT_APP_ANALYTICS_KEY]);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      const cookieFlags = window.location.protocol === 'https:' ? 'SameSite=None; Secure' : 'SameSite=Lax';

      ReactGA.initialize(gaKey, { gaOptions: { cookieFlags } });
      setInitialized(true);
    }
  }, [initialized, gaKey]);

  const gaContextValue = useMemo(() => {
    const capture = (event: GenericGAEvent) => {
      ReactGA.event(mapToGAEvent(event));
    };

    const set = (field: Record<string, string>) => {
      ReactGA.set(field);
    };
    if (initialized) {
      return { capture, set };
    }
  }, [initialized]);

  return <GoogleAnalyticsContext.Provider value={gaContextValue}>{children}</GoogleAnalyticsContext.Provider>;
};

function GoogleAnalyticsContextProviderCookieCheck({ children }: PropsWithChildren) {
  const cookieSupport = navigator.cookieEnabled;

  if (cookieSupport) return <GoogleAnalyticsContextProvider>{children}</GoogleAnalyticsContextProvider>;

  return <>{children}</>;
}

export { GoogleAnalyticsContextProviderCookieCheck as GoogleAnalyticsContextProvider };
