import { Suspense } from 'react';
import Header from '../layout/header/Header';
import Page from '../layout/Page';
import Panel from '../components/Panel';
import MaterialPage from '../layout/MaterialPage';
import MaterialSidebar from '../layout/sidebar/MaterialSidebar';

const panel = (
  <Panel className="panel--default">
    <Panel.Body>
      <div style={{ minHeight: '250px' }} />
    </Panel.Body>
  </Panel>
);

export const withPageSuspense = (Component: React.LazyExoticComponent<any>, props?: Record<string, unknown>) => {
  return (
    <Suspense fallback={<Page header={<Header title="" />}>{panel}</Page>}>
      <Component {...props} />
    </Suspense>
  );
};

export const withMaterialPageSuspense = (
  Component: React.LazyExoticComponent<any>,
  props?: Record<string, unknown>,
) => {
  return (
    <Suspense fallback={<MaterialPage sidebar={<MaterialSidebar />}> {panel} </MaterialPage>}>
      <Component {...props} />
    </Suspense>
  );
};

export const withSuspense = (Component: React.LazyExoticComponent<any>, props?: Record<string, unknown>) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};
