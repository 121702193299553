import { createContext, ReactNode, useMemo } from 'react';

import defaultsNL from '../assets/defaults_nl.json';
import defaultsFR from '../assets/defaults_fr.json';
import flatObject from '../utils/flatObject';

export type SupportedUserLanguage = 'nl' | 'fr';

type TranslationContextValue = {
  lang: SupportedUserLanguage;
  translations: Record<SupportedUserLanguage, Record<string, string>>;
};

const TranslationContext = createContext<TranslationContextValue>({
  lang: 'nl',
  translations: {
    nl: {},
    fr: {},
  },
});

export default TranslationContext;

const nl = Object.fromEntries(flatObject(defaultsNL));
const fr = Object.fromEntries(flatObject(defaultsFR));

export function AssetBasedTranslationContextProvider({
  lang = 'nl',
  children,
}: {
  lang: SupportedUserLanguage;
  children: ReactNode;
}): JSX.Element {
  const translationContextValue = useMemo(
    () => ({
      lang,
      translations: {
        nl,
        fr,
      },
    }),
    [lang],
  );

  return <TranslationContext.Provider value={translationContextValue}>{children}</TranslationContext.Provider>;
}
