import { createContext, PropsWithChildren, useContext, useEffect, useState, useCallback } from 'react';
import useThrowAsyncError from '../../../hooks/useThrowAsyncError';
import { ExerciseCategory, getExerciseCategories } from '../_api/ExerciseCategoryApi';
import ModuleContext from './ModuleContext';

type ExerciseCategoriesContextValue = {
  exerciseCategories: ExerciseCategory[];
  fetchExerciseCategories: () => Promise<void>;
};

const ExerciseCategoriesContext = createContext<ExerciseCategoriesContextValue>({
  exerciseCategories: [],
  fetchExerciseCategories: () => Promise.resolve(),
});

export default ExerciseCategoriesContext;

export function ExerciseCategoriesContextProvider({ children }: PropsWithChildren) {
  const [exerciseCategories, setExerciseCategories] = useState<ExerciseCategory[]>([]);

  const handleError = useThrowAsyncError();

  const { module } = useContext(ModuleContext);

  const fetchExerciseCategories = useCallback(async () => {
    if (!module) return;

    try {
      const { data } = await getExerciseCategories({ moduleId: module.id });

      const flattenedThemes = [
        ...data.filter(theme => theme.id !== 'general'),
        ...(data.find(theme => theme.id === 'general')?.categories ?? []),
      ];

      setExerciseCategories(flattenedThemes);
    } catch (e) {
      handleError(e);
    }
  }, [module, handleError]);

  useEffect(() => {
    fetchExerciseCategories();
  }, [fetchExerciseCategories]);

  return (
    <ExerciseCategoriesContext.Provider value={{ exerciseCategories, fetchExerciseCategories }}>
      {children}
    </ExerciseCategoriesContext.Provider>
  );
}
