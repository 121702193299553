import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import getAccessToken from './BearerToken';

export type ResponsePayloadWithData<TData> = {
  data: TData;
};

export const API_BASE_URL = `${process.env.REACT_APP_P_API_URL || 'http://localhost:3000'}/api`;

function refreshTokenInterceptor(config: InternalAxiosRequestConfig) {
  if (config.skipAuthorization || config.headers?.Authorization) return Promise.resolve(config);

  return getAccessToken().then(accessToken => {
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });
}

function addStudioGroupInterceptor(config: InternalAxiosRequestConfig) {
  config.headers.StudioGroup = process.env.REACT_APP_P_STUDIO_GROUP;
  return config;
}

const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(addStudioGroupInterceptor);
api.interceptors.request.use(refreshTokenInterceptor);

export default api;
