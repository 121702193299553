import classNames from 'classnames';
import { ReactNode, useState } from 'react';

const ROOT_CLASS = 'notification-banner';

type NotificationBannerProps = {
  children: ReactNode;
  variant?: 'info' | 'danger' | 'warning' | 'success';
  startAdornmentIcon?: ReactNode;
  dismissible?: boolean;
  dismissibleStartAdornment?: ReactNode;
  onDismiss?: () => void;
};

const NotificationBanner = ({
  children,
  variant = 'info',
  startAdornmentIcon,
  dismissible = false,
  dismissibleStartAdornment,
  onDismiss,
}: NotificationBannerProps): JSX.Element | null => {
  const [dismissed, setDismissed] = useState(false);

  const dismiss = () => {
    setDismissed(true);
    if (onDismiss) onDismiss();
  };

  if (dismissed) return null;

  return (
    <div className={classNames(ROOT_CLASS, `${ROOT_CLASS}--${variant}`)} role="alert">
      <div className={`${ROOT_CLASS}__icon`}>
        {startAdornmentIcon || (
          <svg className="icon" role="img">
            <use xlinkHref="#icon-notification" />
          </svg>
        )}
      </div>
      <div className={`${ROOT_CLASS}__body`}>
        <span>{children}</span>
      </div>
      {dismissible && (
        <button type="button" className={`${ROOT_CLASS}__close`} onClick={() => dismiss()}>
          {dismissibleStartAdornment && <span>{dismissibleStartAdornment}</span>}
          <svg className="icon" role="img">
            <use xlinkHref="#icon-cross" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default NotificationBanner;
