import { MedialinkKind } from '@pelckmans/business-components/types/Medialink';
import { AudienceInfo } from '@pelckmans/business-components/types/Audiences';
import { GenericGAEvent } from '../contexts/GoogleAnalyticsContext';
import { Provider } from '../modules/login/types';
import ViewModeResults from '../modules/material/results/constants/viewModeResults';
import { ViewMode } from '../modules/material/module-dashboard/components/ViewModeSelector';
import { Audience, getAudiencesByShareState } from '../modules/material/utils/shareUtils';

export const buildLoginEvent = (provider: Provider): GenericGAEvent => ({
  category: 'Login',
  action: 'ButtonClick',
  label: provider,
});

export const buildAppClickEvent = (
  appName: string,
  appType: string,
  moduleName: string,
  location: 'Dashboard' | 'Sidebar',
): GenericGAEvent => ({
  category: 'Material',
  action: 'AppClick',
  location,
  label: appName,
  moduleName,
  appType,
});

export const buildOpenResultsPageEvent = (moduleName: string): GenericGAEvent => ({
  category: 'Results',
  action: 'Open Results (page)',
  label: 'resultaten openen',
  moduleName,
});

export const buildFilterEvent = (
  label: string,
  moduleName: string,
  options: string,
  sort?: string,
): GenericGAEvent => ({
  category: 'Results',
  action: 'Filter Results',
  label,
  moduleName,
  options,
  ...(sort ? { sort } : {}),
});

export const buildFilterResultEvent = (moduleName: string, member: string, viewMode: 'browseTOC' | 'exerciseTOC') => ({
  category: 'Material',
  action: 'ExerciseResults',
  label: member ? 'Student view' : 'Group view',
  moduleName,
  exerciseResultViewMode: viewMode === ViewModeResults.TOC ? 'TOC' : 'Exercise theme',
});

export const buildMaterialViewModeClickEvent = (
  label: ViewMode,
  moduleName: string,
  action: 'navClickViaTab' | 'navClickViaHome',
): GenericGAEvent => ({
  category: 'Material',
  action,
  moduleName,
  label,
});

export type BuildShareMaterial = {
  moduleName: string;
  materialName: string;
  materialKind: MedialinkKind | 'user-material';
  options: Audience[];
  bulk: boolean;
};

type TrackShareMaterial = {
  newAudiences: Audience[];
  oldAudiences: Audience[];
  moduleName: string;
  material: Material;
  onShare(args: BuildShareMaterial): void;
  onStop(args: BuildShareMaterial): void;
  onUnshare(args: BuildShareMaterial): void;
};

type TrackBulkShareMaterial = Omit<TrackShareMaterial, 'oldAudiences'> & { allSharedMaterialNames: string[] };

type Material = {
  name: string;
  kind: BuildShareMaterial['materialKind'];
};

export const trackShareMaterial = (args: TrackShareMaterial): void => {
  const sharedAudiences = getAudiencesByShareState('shared', args.newAudiences, args.oldAudiences);

  const unsharedAudiences = getAudiencesByShareState('unshared', args.oldAudiences, args.newAudiences);

  const buildPayload = (audiences: Audience[]): BuildShareMaterial => ({
    materialName: args.material.name,
    moduleName: args.moduleName,
    materialKind: args.material.kind,
    options: audiences,
    bulk: false,
  });

  // Share material
  if (sharedAudiences.length > 0) {
    args.onShare(buildPayload(sharedAudiences));
  }

  // Stop sharing material (share with no one / no audiences)
  if (args.newAudiences.length === 0) {
    args.onStop(buildPayload(args.oldAudiences));
  }

  // Unshare material (decrease number of audiences of an existing shared material)
  if (args.oldAudiences.length > 0 && args.newAudiences.length > 0 && unsharedAudiences.length > 0) {
    args.onUnshare(buildPayload(unsharedAudiences));
  }
};

export const trackBulkShareMaterial = ({
  newAudiences,
  material,
  moduleName,
  allSharedMaterialNames,
  onShare,
  onStop,
  onUnshare,
}: TrackBulkShareMaterial): void => {
  const oldAudiences: Audience[] = [];

  const sharedAudiences = getAudiencesByShareState('shared', newAudiences, oldAudiences);

  const buildPayload = (audiences: Audience[]): BuildShareMaterial => ({
    materialName: material.name,
    moduleName,
    materialKind: material.kind,
    options: audiences,
    bulk: true,
  });

  if (newAudiences.length === 0) {
    onStop(buildPayload(oldAudiences));
  } else {
    const unshare = allSharedMaterialNames.find(name => name === material.name);

    if (unshare) onUnshare(buildPayload([]));

    onShare(buildPayload(sharedAudiences));
  }
};

export const mapAudienceInfoToAudience = (audiences: AudienceInfo[]) =>
  audiences.map(audience => ({
    ...audience,
    assignment: audience.assignment
      ? {
          ...audience.assignment,
          from: audience.assignment.from?.toString(),
          to: audience.assignment.to?.toString(),
        }
      : undefined,
  }));

const makeAudiencesSummary = (audiences: Audience[]): string => {
  const summary = audiences.reduce(
    (acc, audience) => {
      const { audienceType, assignment } = audience;

      switch (audienceType) {
        case 'school':
          acc.school = true;
          break;
        case 'smartschoolschool':
          acc.school = true;
          break;
        case 'group':
          acc.class = true;
          break;
        case 'member':
          acc.student = true;
          break;
        default:
          break;
      }

      if (assignment) {
        acc.assignment = true;
      }

      if (assignment?.repeatable) {
        acc.repeatable = true;
      }

      return acc;
    },
    {
      school: false,
      class: false,
      student: false,
      assignment: false,
      repeatable: false,
    },
  );

  return `school: ${summary.school}, `
    .concat(`class: ${summary.class}, `)
    .concat(`student: ${summary.student}, `)
    .concat(`assignment: ${summary.assignment}, `)
    .concat(`repeatable: ${summary.repeatable}`);
};

export const buildShareMaterial = (args: BuildShareMaterial): GenericGAEvent => ({
  category: 'Sharing',
  action: 'Share Material',
  label: args.materialName,
  moduleName: args.moduleName,
  medialinkType: args.materialKind,
  options: makeAudiencesSummary(args.options).concat(`, bulk: ${args.bulk}`),
});

export const buildUnshareMaterial = (args: BuildShareMaterial): GenericGAEvent => ({
  category: 'Sharing',
  action: 'Unshare Material',
  label: args.materialName,
  moduleName: args.moduleName,
  medialinkType: args.materialKind,
  options: (args.bulk ? '' : makeAudiencesSummary(args.options).concat(', ')).concat(`bulk: ${args.bulk}`),
});

export const buildStopShareMaterial = (args: BuildShareMaterial): GenericGAEvent => ({
  category: 'Sharing',
  action: 'Stop Share Material',
  label: args.materialName,
  moduleName: args.moduleName,
  medialinkType: args.materialKind,
  options: (args.bulk ? '' : makeAudiencesSummary(args.options).concat(', ')).concat(`bulk: ${args.bulk}`),
});
