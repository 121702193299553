import { useCallback, useContext } from 'react';
import AccountContext from '../contexts/AccountContext';
import useRouting from './useRouting';
import IdentityContext from '../contexts/IdentityContext';
import LocalStorageKeys from '../constants/LocalStorageKeys';
import Cookies from '../utils/Cookies';
import CookieKeys from '../constants/CookieKeys';

export default function useLogout() {
  const {
    account: { provider },
  } = useContext(AccountContext);
  const { REACT_APP_P_API_URL, REACT_APP_P_STUDIO_GROUP } = process.env;
  const { r } = useRouting();
  const { identity } = useContext(IdentityContext);

  const logOut = useCallback(() => {
    if (!provider) throw new Error('Not logged in');

    const pathname = identity.isTeacherAlike && REACT_APP_P_STUDIO_GROUP === 'VO' ? r('login-vo-teacher') : r('login');

    const query = new URLSearchParams({
      return_url: `${window.location.protocol}//${window.location.host}${pathname}`,
      provider,
    });

    localStorage.removeItem(LocalStorageKeys.p_access_token);
    localStorage.removeItem(LocalStorageKeys.p_refresh_token);

    Cookies.remove(CookieKeys.p_authenticated);

    window.location.href = `${REACT_APP_P_API_URL}/api/oauth/logout?${query}`;
  }, [REACT_APP_P_API_URL, r, identity, REACT_APP_P_STUDIO_GROUP, provider]);

  return logOut;
}
