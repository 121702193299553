import classNames from 'classnames';
import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import NotificationBanner from '../../../../components/NotificationBanner';
import Thumbnail from '../../../../components/Thumbnail';
import useRouting from '../../../../hooks/useRouting';
import useTranslation from '../../../../hooks/useTranslation';
import { Module } from '../../_api/ModuleApi';
import ModuleContext from '../../_contexts/ModuleContext';
import QuickMenu from './QuickMenu';

export default function ModuleHeader() {
  const { r } = useRouting();
  const { t } = useTranslation();
  const { subjectSlug, methodSlug, moduleSlug } = useParams();

  const { module } = useContext(ModuleContext);

  if (!subjectSlug || !methodSlug || !moduleSlug) {
    throw new Error('ModuleHeader should only be rendered in Material routes');
  }

  const getBannerText = (mod: Module) => {
    if (mod.phasedOut) return t('COMPONENTS.MODULE_PHASED_OUT_NOTIFIER.MESSAGE');
    if (mod.incomplete) return t('COMPONENTS.MODULE_INCOMPLETE_NOTIFIER.MESSAGE');
  };

  return (
    <>
      {(module?.incomplete || module?.phasedOut) &&
        createPortal(
          <NotificationBanner
            variant="warning"
            startAdornmentIcon={
              <svg className="icon bc-icon--left icon-notification" role="img">
                <use xlinkHref="#icon-notification" />
              </svg>
            }
          >
            {getBannerText(module)}
          </NotificationBanner>,
          document.querySelector('.notification-banners') as HTMLDivElement,
        )}

      <header
        className="header header--module"
        style={module?.styling?.visual ? { backgroundImage: `url('${module?.styling.visual}')` } : {}}
      >
        <div className="base-container">
          <div className="header__wrapper header__wrapper--space-between">
            <Link to={r('material', { subjectSlug, methodSlug, moduleSlug })} className="header__thumbnail-link">
              {module && (
                <Thumbnail
                  img={module.styling?.logo}
                  title={module.name}
                  className={classNames('thumbnail--outline', 'header__thumbnail', 'header__thumbnail--left')}
                />
              )}
            </Link>
            <div className="header__content">
              <QuickMenu />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
