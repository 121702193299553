import { ReactNode } from 'react';
import classNames from 'classnames';

export type PanelProps = {
  'data-testid'?: string;
  children: ReactNode;
  className?: string;
};

function Panel({ 'data-testid': dataTestId, children, className }: PanelProps): JSX.Element {
  return (
    <div className={classNames('panel', className)} data-testid={dataTestId}>
      {children}
    </div>
  );
}

export type PanelHeadingProps = {
  children: ReactNode;
  className?: string;
};

function PanelHeading({ children, className }: PanelHeadingProps) {
  return <h3 className={classNames('panel__heading', className)}>{children}</h3>;
}

export type PanelBodyProps = {
  children: ReactNode;
  className?: string;
  'aria-labelledby'?: string;
};

function PanelBody({ children, className, 'aria-labelledby': ariaLabelledBy }: PanelBodyProps) {
  return (
    <div className={classNames('panel__body', className)} role="region" aria-labelledby={ariaLabelledBy}>
      {children}
    </div>
  );
}

Panel.Body = PanelBody;
Panel.Heading = PanelHeading;

export default Panel;
