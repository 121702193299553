import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import IdentityContext from '../../contexts/IdentityContext';
import useRouting from '../../hooks/useRouting';

export default function RequireAuth() {
  const { pathname, search } = useLocation();

  const urlSearchParams = new URLSearchParams({ returnUrl: [pathname, search].join('') });

  const { r } = useRouting();
  const {
    identity: { isAuthenticated },
  } = useContext(IdentityContext);

  if (!isAuthenticated) {
    return <Navigate to={`${r('login')}?${urlSearchParams.toString()}`} />;
  }

  return <Outlet />;
}
