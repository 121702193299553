export default function* flatObject(
  obj: Record<string, unknown>,
  path?: string,
): Iterable<[key: string, value: string]> {
  for (const [property, value] of Object.entries(obj)) {
    if (value == null) continue;

    const fullPath = [path, property].filter(Boolean).join('.');

    if (typeof value === 'string') yield [fullPath, value];
    else if (value && typeof value === 'object') yield* flatObject(value as Record<string, unknown>, fullPath);
    else throw new Error(`Property path ${fullPath} has an unsupported type`);
  }
}
