import { useCallback, useState } from 'react';

/*
  Error boundaries in react do not catch errors for:
    - Event handlers (learn more)
    - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
    - Server side rendering
    - Errors thrown in the error boundary itself (rather than its children)

  We use a trick to rethrow the error to react (https://github.com/facebook/react/issues/14981).
*/
export default function useThrowAsyncError() {
  const [, setState] = useState();

  return useCallback((error: unknown) => {
    setState(() => {
      throw error;
    });
  }, []);
}
