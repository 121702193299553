function localStorageEnabled(): boolean {
  try {
    // Will fail when access to localStorage is denied by the browser
    localStorage.getItem('not Existing');
    return true;
  } catch (e) {
    return false;
  }
}

const LocalStorage = {
  get: (name: string): string | null => {
    if (!localStorageEnabled()) return null;

    return localStorage.getItem(name);
  },
  set: (name: string, value: string): void => {
    if (!localStorageEnabled()) return;

    localStorage.setItem(name, value);
  },
  remove: (name: string): void => {
    if (!localStorageEnabled()) return;

    localStorage.removeItem(name);
  },
} as const;

export default LocalStorage;
