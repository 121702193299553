import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';

import { ModalContextProvider } from './contexts/ModalContext';

import useRoutes from './routers/useRoutes';
import ErrorElement from './components/errors/ErrorElement';

function App() {
  const routes = useRoutes();

  // https://github.com/remix-run/react-router/issues/10111 React.Fragment id collision
  // A <Route /> is currently used as wrapper for multiple routes within a condition to avoid this problem
  // <ErrorElement /> should be placed on the root level of the router to catch all errors
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <ModalContextProvider>
            <Outlet />
          </ModalContextProvider>
        }
        errorElement={<ErrorElement />}
      >
        {routes}
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default App;
