import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import IdentityContext from './IdentityContext';
import api from '../api';
import useThrowAsyncError from '../hooks/useThrowAsyncError';

export type LicenseTransfer = {
  id: string;
  direction: string;
  email: string;
  license: {
    id: string;
    name: string;
    subject: string;
    description: string;
    isbn: string;
    licenseCode: string;
    validFrom: string;
    validTo: string;
  };
};

type LicenseTransferContextValue = {
  pendingLicenseTransfers: LicenseTransfer[];
  handleLicenseTransfer: (licenseTransferId: string) => void;
  updateLicenseTransfers: (licenseTransfers: LicenseTransfer[]) => void;
};

export const defaultLicenseTransferContextValue: LicenseTransferContextValue = {
  pendingLicenseTransfers: [],
  handleLicenseTransfer: () => {},
  updateLicenseTransfers: () => {},
};

const LicenseTransferContext = createContext<LicenseTransferContextValue>(defaultLicenseTransferContextValue);

export default LicenseTransferContext;

export const LicenseTransferContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [pendingLicenseTransfers, setPendingLicenseTransfers] = useState<LicenseTransfer[]>([]);

  const { identity } = useContext(IdentityContext);

  const handleLicenseTransfer = useCallback(
    (licenseTransferId: string) => {
      setPendingLicenseTransfers(
        pendingLicenseTransfers.filter(licenseTransfer => licenseTransfer.id !== licenseTransferId),
      );
    },
    [pendingLicenseTransfers],
  );

  const handleError = useThrowAsyncError();

  useEffect(() => {
    const fetchPendingLicenseTransfers = async () => {
      const {
        data: { data: licenseTransfer },
      } = await api.get<AxiosResponse<LicenseTransfer[]>>('/studio/licenses/transfers', {
        params: {
          onlyReceiving: true,
        },
      });

      setPendingLicenseTransfers(licenseTransfer);
    };

    if (identity.isAuthenticated) {
      fetchPendingLicenseTransfers().catch(handleError);
    }
  }, [identity, handleError]);

  const licenseTransferContextValue = useMemo(
    () => ({
      pendingLicenseTransfers,
      handleLicenseTransfer,
      updateLicenseTransfers: setPendingLicenseTransfers,
    }),
    [pendingLicenseTransfers, handleLicenseTransfer],
  );

  return (
    <LicenseTransferContext.Provider value={licenseTransferContextValue}>{children}</LicenseTransferContext.Provider>
  );
};
