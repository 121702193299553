import { createContext, useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { AppsContextProvider } from './AppsContext';
import { ModuleContextProvider } from './ModuleContext';
import { ViewMode } from '../module-dashboard/components/ViewModeSelector';
import { DigibookContextProvider } from './DigibookContext';
import { ExerciseCategoriesContextProvider } from './ExerciseCategoriesContext';

type MaterialDataContextValue = {
  selectedNodeIds: string[];
  setSelectedNodeIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedViewMode: ViewMode | undefined;
  setSelectedViewMode: React.Dispatch<React.SetStateAction<ViewMode | undefined>>;
  selectedCategoryId?: string;
  setSelectedCategoryId: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedThemeId?: string;
  setSelectedThemeId: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedExerciseSection?: 'theme' | 'chapter';
  setSelectedExerciseSection: (selectedSection?: 'theme' | 'chapter') => void;
};

export const MaterialDataContext = createContext<MaterialDataContextValue>({
  selectedNodeIds: [],
  setSelectedNodeIds: () => {},
  selectedViewMode: undefined,
  setSelectedViewMode: () => undefined,
  selectedCategoryId: undefined,
  setSelectedCategoryId: () => {},
  selectedThemeId: undefined,
  setSelectedThemeId: () => {},
  selectedExerciseSection: undefined,
  setSelectedExerciseSection: () => {},
});

export default function MaterialDataProvider() {
  const [searchParams] = useSearchParams();
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const [selectedViewMode, setSelectedViewMode] = useState<ViewMode>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
  const [selectedThemeId, setSelectedThemeId] = useState<string>();
  const [selectedExerciseSection, setSelectedExerciseSection] = useState<'theme' | 'chapter'>();
  const selectedNodeId = searchParams.get('nodeId');

  useEffect(() => {
    if (selectedNodeId) {
      setSelectedExerciseSection('chapter');
    }
  }, [selectedNodeId]);

  return (
    <MaterialDataContext.Provider
      value={{
        selectedNodeIds,
        setSelectedNodeIds,
        selectedViewMode,
        setSelectedViewMode,
        selectedCategoryId,
        setSelectedCategoryId,
        selectedThemeId,
        setSelectedThemeId,
        selectedExerciseSection,
        setSelectedExerciseSection,
      }}
    >
      <ModuleContextProvider>
        <AppsContextProvider>
          <DigibookContextProvider>
            <ExerciseCategoriesContextProvider>
              <Outlet />
            </ExerciseCategoriesContextProvider>
          </DigibookContextProvider>
        </AppsContextProvider>
      </ModuleContextProvider>
    </MaterialDataContext.Provider>
  );
}
