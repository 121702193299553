import { PropsWithChildren, ReactNode } from 'react';
import { ModalContextProvider } from '../contexts/ModalContext';
import Sidebar from './sidebar/Sidebar';

export default function Page({
  children,
  className,
  header,
}: PropsWithChildren<{
  header: ReactNode;
  className?: string;
}>) {
  return (
    <ModalContextProvider>
      {header}
      <Sidebar />
      <main className={className}>{children}</main>
    </ModalContextProvider>
  );
}
