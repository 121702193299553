import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Thumbnail from '../../../../components/Thumbnail';
import AccountContext from '../../../../contexts/AccountContext';
import useTranslation from '../../../../hooks/useTranslation';
import useRouting from '../../../../hooks/useRouting';
import IdentityContext from '../../../../contexts/IdentityContext';
import Role from '../../../../core/security/role';
import MyAssignmentsContext from '../../../../contexts/MyAssignmentsContext';
import InvitationContext from '../../../../contexts/InvitationContext';
import useLogout from '../../../../hooks/useLogout';

export default function QuickMenu() {
  const { REACT_APP_P_STUDIO_GROUP } = process.env;

  const { t } = useTranslation();
  const { r } = useRouting();

  const { identity } = useContext(IdentityContext);
  const logOut = useLogout();
  const { assignments } = useContext(MyAssignmentsContext);
  const { pendingInvitations } = useContext(InvitationContext);

  const [isVisible, setIsVisible] = useState(false);
  const {
    account: { displayName, role, avatar },
  } = useContext(AccountContext);

  const totalNotifications = assignments.length + pendingInvitations.length;

  const isVoStudent = REACT_APP_P_STUDIO_GROUP === 'VO' && !identity.isTeacherAlike;

  return (
    <div className="quick-menu" role="button" onClick={() => setIsVisible(!isVisible)}>
      {identity.isAuthenticated && (
        <div className="quick-menu__user-info">
          <strong className="quick-menu__user-name">{displayName}</strong>
          <span className="quick-menu__user-role">{t(role)}</span>
        </div>
      )}
      <div className="quick-menu__avatar">
        <div className="quick-menu__avatar-dropdown">
          <svg className="quick-menu__icon icon-arrow-down" role="img">
            <use xlinkHref="#icon-arrow-down" />
          </svg>
        </div>
        <Thumbnail img={avatar} title="avatar" className="header__thumbnail" />
        {totalNotifications > 0 && (
          <span className="pill pill--info" role="status">
            {totalNotifications}
          </span>
        )}
      </div>
      {isVisible && (
        <nav className="quick-menu__nav">
          {!identity.isAuthenticated && (
            <Link to={r('login')} title={t('COMPONENTS.QUICK_COMPONENT.LOGIN.HOVER')} className="quick-menu__nav-link">
              <svg className="quick-menu__icon icon-login" role="img">
                <use xlinkHref="#icon-login" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.LOGIN.LABEL')}</span>
            </Link>
          )}
          {identity.isAuthenticated && (
            <Link
              to={r('dashboard')}
              title={t('COMPONENTS.QUICK_COMPONENT.HOME.HOVER')}
              className="quick-menu__nav-link"
            >
              <span className="quick-menu__icon quick-menu__icon--logo">
                <img
                  src={process.env.PUBLIC_URL + '/images/p_logo.svg'}
                  alt={t('COMPONENTS.QUICK_COMPONENT.HOME.LABEL')}
                  className="quick-menu__icon quick-menu__icon--logo"
                />
              </span>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.HOME.LABEL')}</span>
            </Link>
          )}
          {identity.isAuthenticated && !isVoStudent && (
            <Link
              to={r('my-account')}
              title={t('COMPONENTS.QUICK_COMPONENT.MY_ACCOUNT.HOVER')}
              className="quick-menu__nav-link"
            >
              <svg className="quick-menu__icon icon-account" role="img">
                <use xlinkHref="#icon-account" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.MY_ACCOUNT.LABEL')}</span>
            </Link>
          )}
          {identity.hasOnlyRole(Role.STUDENT) && ['SO', 'ES'].includes(REACT_APP_P_STUDIO_GROUP) && (
            <Link
              to={r('my-teachers')}
              title={t('COMPONENTS.QUICK_COMPONENT.MY_TEACHERS.HOVER')}
              className="quick-menu__nav-link"
            >
              <svg className="quick-menu__icon bc-icon--right icon-people" role="img">
                <use xlinkHref="#icon-people" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.MY_TEACHERS.LABEL')}</span>
              {pendingInvitations.length > 0 && (
                <span className="pill pill--info" role="status">
                  {pendingInvitations.length}
                </span>
              )}
            </Link>
          )}
          {identity.hasOnlyRole(Role.STUDENT) && ['SO', 'ES'].includes(REACT_APP_P_STUDIO_GROUP) && (
            <Link
              to={r('my-assignments')}
              title={t('COMPONENTS.QUICK_COMPONENT.MY_ASSIGNMENTS.HOVER')}
              className="quick-menu__nav-link"
            >
              <svg className="quick-menu__icon icon-assignment" role="img">
                <use xlinkHref="#icon-assignment" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.MY_ASSIGNMENTS.LABEL')}</span>
              {assignments.length > 0 && (
                <span className="pill pill--assignments" role="status">
                  {assignments.length}
                </span>
              )}
            </Link>
          )}
          {identity.isTeacherAlike && ['SO', 'ES', 'VO'].includes(REACT_APP_P_STUDIO_GROUP) && (
            <Link
              to={r('my-groups')}
              title={t('COMPONENTS.QUICK_COMPONENT.MY_CLASSES.HOVER')}
              className="quick-menu__nav-link"
            >
              <svg className="quick-menu__icon icon-people" role="img">
                <use xlinkHref="#icon-people" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.MY_CLASSES.LABEL')}</span>
            </Link>
          )}
          {!isVoStudent && (
            <Link
              to={r('helpdesk')}
              title={t('COMPONENTS.QUICK_COMPONENT.HELPDESK.HOVER')}
              className="quick-menu__nav-link"
            >
              <svg className="quick-menu__icon icon-info" role="img">
                <use xlinkHref="#icon-info" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.HELPDESK.LABEL')}</span>
            </Link>
          )}

          {identity.isAuthenticated && !isVoStudent && (
            <Link
              to={`${r('dashboard')}?showLicenseActivation`}
              title={t('COMPONENTS.QUICK_COMPONENT.CODE_ACTIVATION.HOVER')}
              className="quick-menu__nav-link"
            >
              <svg className="quick-menu__icon icon-plus" role="img">
                <use xlinkHref="#icon-plus" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.CODE_ACTIVATION.LABEL')}</span>
            </Link>
          )}
          {identity.isAuthenticated && !isVoStudent && (
            <Link
              to={r('catalog')}
              title={t('COMPONENTS.QUICK_COMPONENT.CATALOG.HOVER')}
              className="quick-menu__nav-link"
            >
              <svg className="quick-menu__icon icon-offer" role="img">
                <use xlinkHref="#icon-offer" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.CATALOG.LABEL')}</span>
            </Link>
          )}
          {identity.hasAnyRole([Role.SYSADMIN, Role.EDITOR, Role.PRODUCTION, Role.EXTERNAL]) && (
            <a
              href="/shell/"
              title={t('COMPONENTS.QUICK_COMPONENT.CONTENT_MANAGEMENT.HOVER')}
              className="quick-menu__nav-link"
            >
              <svg className="quick-menu__icon icon-settings" role="img">
                <use xlinkHref="#icon-settings" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.CONTENT_MANAGEMENT.LABEL')}</span>
            </a>
          )}
          {identity.isAuthenticated && (
            <button
              onClick={logOut}
              title={t('COMPONENTS.QUICK_COMPONENT.LOGOUT.HOVER')}
              className="quick-menu__nav-link quick-menu__nav-link--button"
            >
              <svg className="quick-menu__icon icon-logout" role="img">
                <use xlinkHref="#icon-logout" />
              </svg>
              <span className="quick-menu__label">{t('COMPONENTS.QUICK_COMPONENT.LOGOUT.LABEL')}</span>
            </button>
          )}
        </nav>
      )}
    </div>
  );
}
