import { createContext, ReactNode, useMemo } from 'react';
import jwtDecode from 'jwt-decode';
import { useCallback, useState } from 'react';
import LocalStorageKeys from '../constants/LocalStorageKeys';
import Identity from '../core/security/identity';
import Role from '../core/security/role';
import LocalStorage from '../utils/LocalStorage';
import api from '../api';
import { AxiosResponse } from 'axios';
import useThrowAsyncError from '../hooks/useThrowAsyncError';

type IdentityContextValue = {
  identity: Identity;
  onAccessTokenReceived: (accessToken: string) => void;
  refreshIdentity: (refreshToken: string) => void;
};

export const defaultIdentityContextValue = {
  identity: Identity.anonymous(),
  onAccessTokenReceived: () => {},
  refreshIdentity: () => {},
};

const IdentityContext = createContext<IdentityContextValue>(defaultIdentityContextValue);

export default IdentityContext;

export type JwtPayload = {
  sub: string;
  givenname: string;
  surname?: string;
  email?: string;
  roles: Role[];
  author_modules?: string[];
  data?: Record<string, unknown>;
};

const getIdentityFromLocalStorage = (): Identity => {
  const accessToken = LocalStorage.get(LocalStorageKeys.p_access_token);

  if (!accessToken) return Identity.anonymous();

  return parseIdentity(accessToken);
};

const parseIdentity = (accessToken: string): Identity => {
  const payload: JwtPayload = jwtDecode(accessToken);

  return new Identity(
    payload.sub,
    payload.givenname,
    payload.surname,
    payload.email,
    payload.roles,
    payload.author_modules,
    payload.data,
  );
};

export const IdentityContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [identity, setIdentity] = useState<Identity>(getIdentityFromLocalStorage());
  const handleError = useThrowAsyncError();
  const onAccessTokenReceived = useCallback((accessToken: string) => {
    const identity = parseIdentity(accessToken);

    setIdentity(identity);
  }, []);

  const refreshIdentity = useCallback(
    async (refreshToken: string) => {
      try {
        const refreshTokenResponse = await api.post<
          undefined,
          AxiosResponse<{
            access_token: string;
            refresh_token: string;
          }>
        >(
          `/oauth/refresh_token?token=${refreshToken}`,
          {},
          {
            skipAuthorization: true,
          },
        );

        if (refreshTokenResponse && refreshTokenResponse.status === 200) {
          LocalStorage.set(LocalStorageKeys.p_access_token, JSON.stringify(refreshTokenResponse.data.access_token));
          LocalStorage.set(LocalStorageKeys.p_refresh_token, JSON.stringify(refreshTokenResponse.data.refresh_token));

          onAccessTokenReceived(refreshTokenResponse.data.access_token);
        }
      } catch (e) {
        handleError(e);
      }
    },
    [onAccessTokenReceived, handleError],
  );

  const identityValue = useMemo(
    () => ({
      identity,
      onAccessTokenReceived,
      refreshIdentity,
    }),
    [identity, onAccessTokenReceived, refreshIdentity],
  );

  return <IdentityContext.Provider value={identityValue}>{children}</IdentityContext.Provider>;
};
