import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useThrowAsyncError from '../hooks/useThrowAsyncError';
import { getMyAssignments, Assignment } from '../components/my-assignments/api/MyAssignmentsApi';
import IdentityContext from './IdentityContext';

type MyAssignmentsContextValue = {
  assignments: Assignment[];
  refreshAssignments: () => Promise<void>;
};

const defaultMyAssignmentsContextValue: MyAssignmentsContextValue = {
  assignments: [],
  refreshAssignments: () => Promise.resolve(),
};

const MyAssignmentsContext = createContext<MyAssignmentsContextValue>(defaultMyAssignmentsContextValue);

export default MyAssignmentsContext;

export const MyAssignmentsContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [assignments, setAssignments] = useState<Assignment[]>([]);

  const {
    identity: { isAuthenticated },
  } = useContext(IdentityContext);

  const handleError = useThrowAsyncError();

  const fetchAssignments = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const fetchedAssignments = await getMyAssignments();

        setAssignments(fetchedAssignments);
      } catch (err) {
        handleError(err);
      }
    }
  }, [isAuthenticated, handleError]);

  useEffect(() => {
    fetchAssignments();
  }, [fetchAssignments]);

  const myAssignmentsContextValue = useMemo(
    () => ({
      assignments,
      refreshAssignments: fetchAssignments,
    }),
    [assignments, fetchAssignments],
  );

  return <MyAssignmentsContext.Provider value={myAssignmentsContextValue}>{children}</MyAssignmentsContext.Provider>;
};
