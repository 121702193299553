import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AccountContext from './AccountContext';

const DEFAULT_BACKGROUND = `${process.env.PUBLIC_URL}/images/background/bgimage_default.jpg`;

type ImageUrls = { avatar: string; background: string };

type UserImagesContextValue = ImageUrls & {
  updateImage: (imageUrls: Partial<ImageUrls>) => void;
  resetImages: () => void;
};

const UserImagesContext = createContext<UserImagesContextValue>({
  avatar: `${process.env.PUBLIC_URL}/images/avatar/anonymous_avatar.png`,
  background: DEFAULT_BACKGROUND,
  updateImage: () => {},
  resetImages: () => {},
});

export function UserImagesContextProvider({ children }: PropsWithChildren) {
  const {
    account: { avatar: accountAvatar, backgroundImage: accountBackground },
  } = useContext(AccountContext);

  const [userImageAvatar, setAvatar] = useState(accountAvatar);

  useEffect(() => {
    setAvatar(accountAvatar);
  }, [accountAvatar]);

  const [userImageBackground, setBackground] = useState(accountBackground);

  useEffect(() => {
    if (accountBackground) {
      setBackground(accountBackground);
    }
  }, [accountBackground]);

  const updateImage = ({ avatar, background }: Partial<ImageUrls>) => {
    if (avatar) {
      setAvatar(avatar);
    }

    if (background) {
      setBackground(background);
    }
  };

  const resetImages = useCallback(() => {
    setAvatar(accountAvatar);
    setBackground(accountBackground);
  }, [accountAvatar, accountBackground]);

  const userImagesContextValue = useMemo(
    () => ({
      avatar: userImageAvatar,
      background: userImageBackground || DEFAULT_BACKGROUND,
      updateImage,
      resetImages,
    }),
    [resetImages, userImageAvatar, userImageBackground],
  );

  return <UserImagesContext.Provider value={userImagesContextValue}>{children}</UserImagesContext.Provider>;
}

export default UserImagesContext;
