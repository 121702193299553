import { Navigate, Route } from 'react-router-dom';
import useRouting from '../hooks/useRouting';
import { useContext, lazy } from 'react';
import IdentityContext from '../contexts/IdentityContext';
import useBaseRouter from './useBaseRouter';
import { withPageSuspense, withSuspense } from '../utils/suspense';

const Login = lazy(() => import('../modules/login/Login'));
const RequireAuth = lazy(() => import('../components/routing/RequireAuth'));
const TeacherProfile = lazy(() => import('../modules/my-account/components/TeacherProfile'));
const Licenses = lazy(() => import('../modules/my-account/components/Licenses'));
const LinkedAccounts = lazy(() => import('../modules/my-account/components/LinkedAccounts'));
const PersonalInformation = lazy(() => import('../modules/my-account/components/PersonalInformation'));
const MyAccount = lazy(() => import('../modules/my-account/MyAccount'));
const Dashboard = lazy(() => import('../modules/dashboard/Dashboard'));
const Helpdesk = lazy(() => import('../modules/helpdesk/Helpdesk'));
const HelpdeskDetail = lazy(() => import('../modules/helpdesk/HelpdeskDetail'));

export default function useBaoRouter() {
  const { r } = useRouting();

  const { identity } = useContext(IdentityContext);
  const { isTeacherAlike } = identity;

  const baoRoutes = (
    <>
      <Route path="/" element={<Navigate to={r('dashboard')} replace />} />
      <Route path={r('login')} element={withPageSuspense(Login)} />

      <Route path={r('helpdesk')} element={withPageSuspense(Helpdesk)}>
        <Route path={r('helpdesk')} element={withSuspense(HelpdeskDetail, { target: 'teacher' })} />
      </Route>

      <Route element={<RequireAuth />}>
        <Route path={r('dashboard')} element={withPageSuspense(Dashboard)} />
        <Route path={r('my-account')} element={withPageSuspense(MyAccount)}>
          <Route index element={<Navigate to={r('my-account.personal-information')} replace />} />
          <Route path={r('my-account.personal-information')} element={withSuspense(PersonalInformation)} />
          <Route path={r('my-account.linked-accounts')} element={withSuspense(LinkedAccounts)} />
          <Route path={r('my-account.licenses')} element={withSuspense(Licenses)} />

          {isTeacherAlike && <Route path={r('my-account.teacher-profile')} element={withSuspense(TeacherProfile)} />}
        </Route>
      </Route>
    </>
  );

  return useBaseRouter(baoRoutes);
}
