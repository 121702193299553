import jwt, { JwtPayload } from 'jwt-decode';
import axios from 'axios';
import { API_BASE_URL } from '.';

import LocalStorageKeys from '../constants/LocalStorageKeys';
import LocalStorage from '../utils/LocalStorage';
import Cookies from '../utils/Cookies';
import CookieKeys from '../constants/CookieKeys';

export const TOKEN_GRACE_PERIOD_IN_SECONDS = 120;

let refreshTokenPromise: Promise<string> | null;

export class RefreshTokenFailedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export default function getAccessToken() {
  if (refreshTokenPromise) return refreshTokenPromise;

  // API without interceptor
  const baseApi = axios.create({
    baseURL: API_BASE_URL,
  });

  const accessToken = LocalStorage.get(LocalStorageKeys.p_access_token);
  const refreshToken = LocalStorage.get(LocalStorageKeys.p_refresh_token);

  if (!accessToken || !refreshToken) return Promise.resolve(null);

  const parsedAccessToken = JSON.parse(accessToken);
  const parsedRefreshToken = JSON.parse(refreshToken);

  const decodedToken = jwt<JwtPayload>(parsedAccessToken);

  const isJwtTokenExpired =
    Math.floor(new Date().getTime() / 1000) + TOKEN_GRACE_PERIOD_IN_SECONDS > (decodedToken.exp || 0);

  if (isJwtTokenExpired) {
    const query = new URLSearchParams({ token: parsedRefreshToken }).toString();

    refreshTokenPromise = baseApi
      .post(`/oauth/refresh_token?${query}`)
      .then(({ data: { access_token: newAccessToken, refresh_token: newRefreshToken } }) => {
        LocalStorage.set(LocalStorageKeys.p_access_token, JSON.stringify(newAccessToken));
        LocalStorage.set(LocalStorageKeys.p_refresh_token, JSON.stringify(newRefreshToken));

        const event = new CustomEvent('tokens-updated');
        window.dispatchEvent(event);

        return newAccessToken;
      })
      .catch(error => {
        LocalStorage.remove(LocalStorageKeys.p_access_token);
        LocalStorage.remove(LocalStorageKeys.p_refresh_token);
        LocalStorage.remove(LocalStorageKeys.provider);

        Cookies.remove(CookieKeys.p_authenticated);

        throw error.response?.status === 401 ? new RefreshTokenFailedError('Failed to get refresh token') : error;
      })
      .finally(() => {
        refreshTokenPromise = null;
      });

    return refreshTokenPromise;
  }

  return Promise.resolve(parsedAccessToken);
}
