import api from '../../../api';

export type Digibook = {
  id: string;
  module: {
    id: string;
    bookType: string;
    name: string;
    niceName: string;
    logo: {
      id: string;
      href: string;
    };
  };
  cover?: {
    id: string;
  };
  backCover?: {
    id: string;
  };
  answerLayer?: {
    id: string;
  };
  bookLayer?: {
    id: string;
  };
  teacherFeaturesEnabled: boolean;
  teacherDigitalManualLicense: boolean;
  teacherDemoProductLicense: boolean;
  pageRanges: {
    nodeId: string;
    from: number;
    to: number;
  }[];
  totalPages?: number;
  systemToken: string;
  allowedRanges?: {
    from: number;
    to: number;
  }[];
  disableAnswerLayerBlend: boolean;
  manual?: {
    id: string;
  };
  manualMapping?: {
    id: string;
    excludeFrom: string;
    from: number;
    to: number;
  }[];
  manualMargins?: {
    top: number;
    left: number;
    height: number;
    width: number;
    angle: number;
  };
  totalManualPages?: number;
  pageDimensions?: {
    width: number;
    height: number;
  };
};

export async function getDigibook(digibookId: string) {
  const { data } = await api.get<Digibook>(`/studio/digibooks/${digibookId}`);

  return data;
}
