export type Props = {
  headerText: string;
  message?: string;
  link: {
    to: string;
    text: string;
  };
};

export default function ErrorPage({ headerText, link, message }: Props) {
  return (
    <div className="error-screen" data-testid="error-screen">
      <img src={process.env.PUBLIC_URL + '/images/p_logo.svg'} alt="logo Pelckmans" className="pelckmans-logo" />
      <h1 className="heading-1">{headerText}</h1>

      {message && <p>{message}</p>}

      <a
        href={link.to}
        className="bc-button__button bc-button__primary bc-button__outline back-to-home"
        data-testid="native-error-link"
      >
        {link.text}
      </a>
    </div>
  );
}
