import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Digibook, getDigibook } from '../_api/DigibookApi';
import { AxiosError } from 'axios';
import useThrowAsyncError from '../../../hooks/useThrowAsyncError';
import AppsContext from './AppsContext';
import ModuleContext from './ModuleContext';
import { App } from '../_api/AppsApi';

type DigibookContextValue = {
  digibook?: Digibook;
};

const DigibookContext = createContext<DigibookContextValue>({});

export default DigibookContext;

function isDigibookApp(
  app: App,
): app is App & { state: 'published'; platformType: 'P intern'; linkedApp: { type: 'digibook' } } {
  return Boolean(
    app.state === 'published' && app.platformType === 'P intern' && app.linkedApp && app.linkedApp.type === 'digibook',
  );
}

export function DigibookContextProvider({ children }: PropsWithChildren) {
  const { apps } = useContext(AppsContext);
  const { module, selectedModule } = useContext(ModuleContext);

  const [digibook, setDigibook] = useState<Digibook>();

  const digibookApps = useMemo(() => apps.filter(isDigibookApp), [apps]);

  const handleError = useThrowAsyncError();

  useEffect(() => {
    const fetchDigibook = async () => {
      const currentModule = selectedModule || module;

      if (!currentModule) return;

      try {
        const digibookAppForModule = digibookApps.find(app => app.moduleId === currentModule.id);

        if (!digibookAppForModule) return setDigibook(undefined);

        const digibook = await getDigibook(digibookAppForModule.linkedApp.id);

        setDigibook(digibook);
      } catch (e) {
        if ((e as AxiosError).response?.status !== 404) handleError(e);
      }
    };

    fetchDigibook();
  }, [digibookApps, handleError, module, selectedModule]);

  return <DigibookContext.Provider value={{ digibook }}>{children}</DigibookContext.Provider>;
}
