import useRouting from '../hooks/useRouting';
import React, { useContext } from 'react';
import IdentityContext from '../contexts/IdentityContext';
import { Navigate, Route } from 'react-router-dom';
import useBaseRouter from './useBaseRouter';
import { withPageSuspense, withSuspense } from '../utils/suspense';

const Login = React.lazy(() => import('../modules/login/Login'));
const RequireAuth = React.lazy(() => import('../components/routing/RequireAuth'));
const GroupInvite = React.lazy(() => import('../components/group-invite/GroupInvite'));
const MyGroups = React.lazy(() => import('../modules/my-groups/MyGroups'));
const MyTeachers = React.lazy(() => import('../modules/my-teachers/MyTeachers'));
const TeacherProfile = React.lazy(() => import('../modules/my-account/components/TeacherProfile'));
const Licenses = React.lazy(() => import('../modules/my-account/components/Licenses'));
const LinkedAccounts = React.lazy(() => import('../modules/my-account/components/LinkedAccounts'));
const PersonalInformation = React.lazy(() => import('../modules/my-account/components/PersonalInformation'));
const MyAccount = React.lazy(() => import('../modules/my-account/MyAccount'));
const Dashboard = React.lazy(() => import('../modules/dashboard/Dashboard'));
const Helpdesk = React.lazy(() => import('../modules/helpdesk/Helpdesk'));
const HelpdeskTargetSelector = React.lazy(() => import('../modules/helpdesk/HelpdeskTargetSelector'));
const HelpdeskDetail = React.lazy(() => import('../modules/helpdesk/HelpdeskDetail'));
const MyAssignmentsPage = React.lazy(() => import('../modules/my-assignments/MyAssignmentsPage'));

export default function useDefaultRouter() {
  const { r } = useRouting();

  const { identity } = useContext(IdentityContext);
  const { isTeacherAlike } = identity;

  const defaultRoutes = (
    <>
      <Route path="/" element={<Navigate to={r('dashboard')} replace />} />
      <Route path={r('login')} element={withPageSuspense(Login)} />
      <Route path="/invite/:id" element={withPageSuspense(GroupInvite)} />

      <Route path={r('helpdesk')} element={withPageSuspense(Helpdesk)}>
        <Route>
          <Route index element={<HelpdeskTargetSelector />} />
          <Route path={r('helpdesk.student')} element={withSuspense(HelpdeskDetail, { target: 'student' })} />
          <Route path={r('helpdesk.teacher')} element={withSuspense(HelpdeskDetail, { target: 'teacher' })} />
        </Route>
      </Route>

      <Route element={<RequireAuth />}>
        <Route path={r('dashboard')} element={withPageSuspense(Dashboard)} />
        <Route path={r('my-account')} element={withPageSuspense(MyAccount)}>
          <Route index element={<Navigate to={r('my-account.personal-information')} replace />} />
          <Route path={r('my-account.personal-information')} element={withSuspense(PersonalInformation)} />
          <Route path={r('my-account.linked-accounts')} element={withSuspense(LinkedAccounts)} />
          <Route path={r('my-account.licenses')} element={withSuspense(Licenses)} />

          {isTeacherAlike && <Route path={r('my-account.teacher-profile')} element={withSuspense(TeacherProfile)} />}
        </Route>
        {isTeacherAlike ? (
          <Route path={r('my-groups')} element={withPageSuspense(MyGroups)} />
        ) : (
          <Route>
            <Route path={r('my-teachers')} element={withPageSuspense(MyTeachers)} />
            <Route path={r('my-assignments')} element={withPageSuspense(MyAssignmentsPage)} />
          </Route>
        )}
      </Route>
    </>
  );

  return useBaseRouter(defaultRoutes);
}
