import { Route } from 'react-router-dom';
import useRouting from '../hooks/useRouting';
import RequireAuth from '../components/routing/RequireAuth';
import { withPageSuspense, withSuspense, withMaterialPageSuspense } from '../utils/suspense';
import { lazy } from 'react';

const Catalog = lazy(() => import('../modules/catalog/Catalog'));
const TermsAndConditions = lazy(() => import('../modules/terms-and-conditions/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('../modules/privacy-policy/PrivacyPolicy'));
const AuthCallback = lazy(() => import('../components/routing/AuthCallback'));
const ConnectCallback = lazy(() => import('../components/routing/ConnectCallback'));
const DisconnectCallback = lazy(() => import('../components/routing/DisconnectCallback'));
const MaterialDataProvider = lazy(() => import('../modules/material/_contexts/MaterialDataProvider'));
const ModuleDashboard = lazy(() => import('../modules/material/module-dashboard/ModuleDashboard'));
const Browse = lazy(() => import('../modules/material/browse/Browse'));
const Exercise = lazy(() => import('../modules/material/exercise/Exercise'));
const Assignments = lazy(() => import('../modules/material/assignments/Assignments'));
const Results = lazy(() => import('../modules/material/results/Results'));
const Search = lazy(() => import('../modules/material/search/Search'));
const LaunchMaterial = lazy(() => import('../modules/launch-material/LaunchMaterial'));
const NotFound = lazy(() => import('../components/errors/NotFound'));
const StandaloneWordlist = lazy(() => import('../modules/material/_wordlist/StandaloneWordlist'));
const AppLayout = lazy(() => import('../AppLayout'));

export default function useBaseRouter(studioGroupRoutes: JSX.Element) {
  const { r } = useRouting();

  return (
    <Route>
      <Route element={withPageSuspense(AppLayout)}>
        <Route path={r('terms-and-conditions')} element={withSuspense(TermsAndConditions)} />
        <Route path={r('privacy-policy')} element={withSuspense(PrivacyPolicy)} />

        <Route path="/auth-callback" element={withSuspense(AuthCallback)} />
        <Route path="/connect-callback" element={withSuspense(ConnectCallback)} />
        <Route path="/disconnect-callback" element={withSuspense(DisconnectCallback)} />

        <Route element={<RequireAuth />}>
          <Route path={r('catalog')} element={withPageSuspense(Catalog)} />
        </Route>

        <Route
          path={r('material')}
          element={withSuspense(MaterialDataProvider)}
          handle={{ includeLoginNotificationBanner: true }}
        >
          <Route index element={withSuspense(ModuleDashboard)} />
          <Route handle={{ isMaterial: true }}>
            <Route path={r('material.browse')} element={withMaterialPageSuspense(Browse)} />
            <Route path={r('material.exercise')} element={withMaterialPageSuspense(Exercise)} />
            <Route element={<RequireAuth />}>
              <Route path={r('material.assignments')} element={withMaterialPageSuspense(Assignments)} />
              <Route path={r('material.results')} element={withMaterialPageSuspense(Results)} />
            </Route>
            <Route path={r('material.search')} element={withMaterialPageSuspense(Search)} />
          </Route>
        </Route>

        {studioGroupRoutes}
      </Route>

      <Route path="*" element={withSuspense(NotFound)} />
      <Route path="/word-list/:moduleSlug/:wordlistId" element={withSuspense(StandaloneWordlist)} />
      <Route path="/link/:superModule/:selectedModule/:nodeId/:medialinkId?" element={withSuspense(LaunchMaterial)} />
    </Route>
  );
}
