import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import HighestRoleExtractor from '../core/security/highest-role-extractor';
import Role from '../core/security/role';
import Identity from '../core/security/identity';
import IdentityContext from './IdentityContext';
import { AccountResponsePayload, fetchAccount, ImageInfo } from '../modules/my-account/api/MyAccountApi';
import useThrowAsyncError from '../hooks/useThrowAsyncError';

const getBackgroundImage = (background: Partial<ImageInfo>) => {
  const { customImage, predefinedImage } = background;

  if (customImage) return `${process.env.REACT_APP_P_API_URL}/api/files/${customImage}`;

  return `${process.env.PUBLIC_URL}/images/background/${predefinedImage}`;
};

const getAvatarImage = (avatar: Partial<ImageInfo>) => {
  const { customImage, predefinedImage } = avatar;

  if (customImage) return `${process.env.REACT_APP_P_API_URL}/api/files/${customImage}`;

  if (predefinedImage) return `${process.env.PUBLIC_URL}/images/avatar/${predefinedImage}`;

  return `${process.env.PUBLIC_URL}/images/avatar/avatarimage_default.jpg`;
};

const getDisplayName = ({
  nickName,
  firstName,
  lastName,
}: {
  nickName?: string;
  firstName?: string;
  lastName?: string;
}) => {
  if (nickName) return nickName;

  if (firstName && lastName) {
    return [firstName, lastName].join(' ');
  }

  return undefined;
};

const getRoleTranslationKey = (role: Role, author = false) => {
  switch (role) {
    case Role.STUDENT:
      // a user can have multiple roles (teacherAlike)
      // therefore a user could be an author
      return author ? 'ROLES.AUTHOR' : 'ROLES.STUDENT';
    case Role.TEACHER:
      return author ? 'ROLES.AUTHOR' : 'ROLES.TEACHER';
    case Role.EXTERNAL:
      return 'ROLES.EXTERNAL';
    case Role.SALES:
      return 'ROLES.SALES';
    case Role.PUBLISHER:
      return 'ROLES.PUBLISHER';
    case Role.EDITOR:
      return 'ROLES.EDITOR';
    case Role.SUPPORT:
      return 'ROLES.SUPPORT';
    case Role.PRODUCTION:
      return 'ROLES.PRODUCTION';
    case Role.SYSADMIN:
      return 'ROLES.SYSADMIN';
    default:
      return role;
  }
};

export type AccountPayload = Partial<Omit<AccountResponsePayload, 'avatar' | 'background'>> & {
  avatar?: string | Partial<ImageInfo>;
  background?: string | Partial<ImageInfo>;
};

export const toAccount = ({
  payload,
  accountInfo = defaultAccount,
  identity,
}: {
  payload: AccountPayload;
  accountInfo?: Account;
  identity: Identity;
}): AccountContextValue['account'] => {
  const avatar = payload.avatar
    ? typeof payload.avatar === 'object'
      ? getAvatarImage(payload.avatar)
      : payload.avatar
    : accountInfo.avatar;

  const backgroundImage = payload.background
    ? typeof payload.background === 'object'
      ? getBackgroundImage(payload.background)
      : payload.background
    : accountInfo.backgroundImage;

  const highestRole = HighestRoleExtractor.extract(identity.roles);

  return {
    avatar,
    backgroundImage,
    role: getRoleTranslationKey(highestRole, identity.isAuthor),
    displayName: getDisplayName(payload) ?? accountInfo.displayName,
    oauthIdentities: payload.identities ?? accountInfo.oauthIdentities,
    unsyncedSmartschoolUrls: payload.unsyncedSmartschoolUrls ?? accountInfo.unsyncedSmartschoolUrls,
    smartschoolSchools: payload.smartschoolSchools ?? accountInfo.smartschoolSchools,
    school: payload.school ?? accountInfo.school,
    nickName: payload.nickName ?? '',
    isCandidateTeacher: payload.isCandidateTeacher ?? accountInfo.isCandidateTeacher,
    existingPassword: payload.existingPassword ?? accountInfo.existingPassword,
    primaryEmail: payload.primaryEmail ?? accountInfo.primaryEmail,
    verifiedEmail: payload.verifiedEmail ?? accountInfo.verifiedEmail,
    provider: payload.provider,
  };
};

type Account = {
  avatar: string;
  role: string;
  displayName?: string;
  backgroundImage?: string;
  nickName?: string;
  oauthIdentities?: AccountResponsePayload['identities'];
  school?: AccountResponsePayload['school'];
  isCandidateTeacher: boolean;
  provider?: AccountResponsePayload['provider'];
} & Pick<
  AccountResponsePayload,
  'unsyncedSmartschoolUrls' | 'smartschoolSchools' | 'primaryEmail' | 'existingPassword' | 'verifiedEmail'
>;

type AccountContextValue = {
  account: Account;
  refreshAccount: () => void;
};

const defaultAccount: Account = {
  avatar: `${process.env.PUBLIC_URL}/images/avatar/anonymous_avatar.png`,
  role: getRoleTranslationKey(Role.STUDENT),
  displayName: undefined,
  unsyncedSmartschoolUrls: [],
  smartschoolSchools: [],
  nickName: '',
  school: undefined,
  isCandidateTeacher: false,
  primaryEmail: undefined,
  verifiedEmail: undefined,
  existingPassword: undefined,
  provider: undefined,
};

export const defaultAccountContextValue: AccountContextValue = {
  account: defaultAccount,
  refreshAccount: () => {},
};

const AccountContext = createContext<AccountContextValue>(defaultAccountContextValue);

export default AccountContext;

export function AccountContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const { identity } = useContext(IdentityContext);

  const [accountInfo, setAccountInfo] = useState<Account>(defaultAccount);

  const handleError = useThrowAsyncError();

  const getAccount = useCallback(
    async (acct?: AccountPayload) => {
      if (acct) {
        setAccountInfo(accountInfo => toAccount({ payload: acct, accountInfo, identity }));
        return;
      }

      try {
        const account = await fetchAccount();

        setAccountInfo(toAccount({ payload: account, identity }));
      } catch (err) {
        handleError(err);
      }
    },
    [identity, handleError],
  );

  useEffect(() => {
    if (!identity.isAuthenticated) return;

    getAccount();
  }, [getAccount, identity]);

  const accountValue = useMemo(
    () => ({
      account: accountInfo,
      refreshAccount: getAccount,
    }),
    [accountInfo, getAccount],
  );

  return <AccountContext.Provider value={accountValue}>{children}</AccountContext.Provider>;
}
