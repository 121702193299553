import PelckmansHeader from './PelckmansHeader';
import PortaalHeader from './PortaalHeader';

const Header = ({ title, subtitle }: { title: string; subtitle?: string }) => {
  return process.env.REACT_APP_P_STUDIO_GROUP === 'BAO' ? (
    <PelckmansHeader title={title} subtitle={subtitle} />
  ) : (
    <PortaalHeader title={title} subtitle={subtitle} />
  );
};

export default Header;
