import classNames from 'classnames';
import { PropsWithChildren, useContext } from 'react';
import { MaterialDataContext } from '../../modules/material/_contexts/MaterialDataProvider';
import BackToDashboard from './components/BackToDashboard';
import MaterialSidebarNavigation from './components/MaterialSidebarNavigation';

export default function MaterialSidebar({ children }: PropsWithChildren) {
  const { selectedViewMode } = useContext(MaterialDataContext);

  return (
    <aside className={classNames('aside', { [`aside--${selectedViewMode}`]: selectedViewMode })}>
      <div className="material-sidebar">
        <BackToDashboard />
        <MaterialSidebarNavigation />
        <div className="material-sidebar-content-wrapper">{children}</div>
      </div>
    </aside>
  );
}
