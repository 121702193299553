import { Navigate, Route } from 'react-router-dom';
import { useContext, lazy } from 'react';
import useRouting from '../hooks/useRouting';
import useBaseRouter from './useBaseRouter';
import IdentityContext from '../contexts/IdentityContext';
import { withPageSuspense, withSuspense } from '../utils/suspense';

const LoginStudentVO = lazy(() => import('../modules/login/vo/LoginStudent.vo'));
const Login = lazy(() => import('../modules/login/Login'));
const RequireAuth = lazy(() => import('../components/routing/RequireAuth'));
const MyGroups = lazy(() => import('../modules/my-groups/MyGroups'));
const TeacherProfile = lazy(() => import('../modules/my-account/components/TeacherProfile'));
const Licenses = lazy(() => import('../modules/my-account/components/Licenses'));
const LinkedAccounts = lazy(() => import('../modules/my-account/components/LinkedAccounts'));
const PersonalInformation = lazy(() => import('../modules/my-account/components/PersonalInformation'));
const MyAccount = lazy(() => import('../modules/my-account/MyAccount'));
const Dashboard = lazy(() => import('../modules/dashboard/Dashboard'));
const Helpdesk = lazy(() => import('../modules/helpdesk/Helpdesk'));
const HelpdeskDetail = lazy(() => import('../modules/helpdesk/HelpdeskDetail'));
const MyAssignmentsPage = lazy(() => import('../modules/my-assignments/MyAssignmentsPage'));

export default function useVORouter() {
  const { r } = useRouting();

  const {
    identity: { isTeacherAlike },
  } = useContext(IdentityContext);

  const VORoutes = (
    <>
      <Route path="/" element={<Navigate to={r('dashboard')} replace />} />
      <Route path={r('login')} element={withPageSuspense(LoginStudentVO)} />
      <Route path={r('login-vo-teacher')} element={withPageSuspense(Login)} />

      <Route path={r('helpdesk')} element={withPageSuspense(Helpdesk)}>
        <Route index element={withSuspense(HelpdeskDetail, { target: 'teacher' })} />
      </Route>

      <Route element={<RequireAuth />}>
        <Route path={r('dashboard')} element={withPageSuspense(Dashboard)} />

        {isTeacherAlike && (
          <Route path={r('my-account')} element={withPageSuspense(MyAccount)}>
            <Route index element={<Navigate to={r('my-account.personal-information')} replace />} />
            <Route path={r('my-account.personal-information')} element={withSuspense(PersonalInformation)} />
            <Route path={r('my-account.linked-accounts')} element={withSuspense(LinkedAccounts)} />
            <Route path={r('my-account.licenses')} element={withSuspense(Licenses)} />
            <Route path={r('my-account.teacher-profile')} element={withSuspense(TeacherProfile)} />
          </Route>
        )}

        {isTeacherAlike && <Route path={r('my-groups')} element={withPageSuspense(MyGroups)} />}

        {!isTeacherAlike && <Route path={r('my-assignments')} element={withPageSuspense(MyAssignmentsPage)} />}
      </Route>
    </>
  );

  return useBaseRouter(VORoutes);
}
