import React, { createContext, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import uiComponentsConfig from '../UIComponentsConfig';

type ModalContextValue = {
  showModal: (modal: JSX.Element, hasPriority?: boolean) => void;
  closeModal: (id: string, disableAnimation?: boolean) => void;
};

const ModalContext = createContext<ModalContextValue>({
  showModal: () => {},
  closeModal: () => {},
});

export default ModalContext;

const MODAL_CLOSING_CLASSNAME = 'bc-modal-dialog--closing';

type Elements = Record<string, [element: JSX.Element, priority: boolean]>;

export const ModalContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [elements, setElements] = useState<Elements>({});

  const showModal = useCallback((modal: JSX.Element, hasPriority = false) => {
    setElements(els => {
      return {
        ...els,
        [modal.props.id]: [modal, hasPriority],
      };
    });
  }, []);

  const closeModal = useCallback((id: string, disableAnimation?: boolean) => {
    document.body.classList.add(MODAL_CLOSING_CLASSNAME);

    const close = () => {
      document.body.classList.remove(MODAL_CLOSING_CLASSNAME);
      setElements(els => {
        const { [id]: _, ...nextElements } = els;
        return nextElements;
      });
    };

    if (disableAnimation) {
      close();
    } else {
      setTimeout(close, uiComponentsConfig.closeAnimationInMs);
    }
  }, []);

  const nonPriority = Object.values(elements).filter(([_, priority]) => !priority);
  const priority = Object.values(elements).filter(([_, priority]) => priority);
  const elementsToRender = [...nonPriority, ...priority].map(([el]) => el);

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {children}

      {elementsToRender.map(el => createPortal(el, document.getElementById('modals') as HTMLDivElement))}
    </ModalContext.Provider>
  );
};
