import { createRoot } from 'react-dom/client';
import App from './App';
import AppDataProvider from './AppDataProvider';
import ErrorBoundary from './components/errors/ErrorBoundary';
import { ReactComponent as Icons } from './assets/icons/icons.svg';

import 'react-date-range/dist/styles.css';

import './scss/index.scss';

import '@pelckmans/business-components/css/business-components.css';
import '@pelckmans/business-components/css/business-components-modules.css';
import './scss/business-components.scss';

const { REACT_APP_P_STUDIO_GROUP } = process.env;

document.body.classList.add(`body--${REACT_APP_P_STUDIO_GROUP.toLowerCase()}`);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ErrorBoundary>
    <AppDataProvider>
      <App />
      <Icons />
    </AppDataProvider>
  </ErrorBoundary>,
);
